import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import RouterGroup from "../components/RouterGroup.vue";

Vue.use(VueRouter);

function reroute(original_to, new_path, next) {
  let query = original_to.query;
  query["next"] = original_to.path;
  next({ path: new_path, query: query, hash: original_to.hash });
}

const routes = [
  {
    path: "/",
    component: () => import("@/views/Init.vue"),
  },
  {
    path: "/confirm",
    component: () => import("@/views/Confirm.vue"),
  },
  {
    path: "/invited",
    component: () => import("@/views/Invited.vue"),
  },
  {
    path: "/reset-password",
    component: () => import("@/views/Reset.vue"),
  },
  {
    path: "/home",
    component: () => import("@/views/Home.vue"),
    beforeEnter(to, from, next) {
      if (store.state.init) {
        next();
        //if (store.getters['user/is_authenticated'] === true) {
        //    next()
        //} else {
        //    reroute(to, '/signin', next)
        //}
      } else {
        reroute(to, "/", next);
      }
    },
    children: [
      {
        path: "",
        redirect: "/investments/browse-all",
      },
      {
        path: "/forgot-password",
        component: () => import("@/views/ForgotPassword.vue"),
      },
      {
        path: "/signin",
        name: "signin",
        component: () => import("@/views/SignIn.vue"),
        props: ({ params }) => ({
          from: params.from,
        }),
      },
      {
        path: "/signup",
        component: () => import("@/views/SignUp.vue"),
      },
      {
        path: "/investments/:tab",
        component: () =>
          import(
            /* webpackChunkName: "propertiesBrowseAll" */ "@/views/Listings.vue"
          ),
        props: ({ params }) => ({ tab: params.tab === "browse-all" ? 1 : 2 }),
      },
      {
        path: "/community/:tab",
        component: () => import("@/views/LandingPageFeed.vue"),
        props: ({ params }) => ({ tab: params.tab === "feed" ? 1 : 2 }),
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: () => import("@/views/Notifications.vue"),
      },
      {
        path: "/search",
        component: () => import("@/views/Search.vue"),
      },
      {
        path: "/investments/:state/:city/:id",
        component: () => import("@/views/HomeProfile.vue"),
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
      },
      {
        path: "/pitch/:name",
        component: () => import("@/views/HomeProfile.vue"),
        props: ({ params }) => ({ name: params.name }),
      },
      {
        path: "/users/:id",
        component: () => import("@/views/PublicProfile.vue"),
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
      },
      {
        path: "/investments/:state/:city/:id/money-allocation",
        component: () => import("@/views/HomeMoneyAllocation.vue"),
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
      },
      {
        path: "/strategies/:name",
        component: () => import("@/views/StrategyProfile.vue"),
        props: true,
      },
      {
        path: "/banking",
        component: () => import("@/views/Banking.vue"),
      },
      {
        path: "/your-homes",
        component: () => import("@/views/YourHomes.vue"),
      },
      {
        path: "/your-pending-homes",
        component: () => import("@/views/YourPendingHomes.vue"),
      },
      {
        path: "/your-listed-homes",
        component: () => import("@/views/YourListedHomes.vue"),
      },
      {
        path: "/signout",
        component: () => import("@/views/SignOut.vue"),
      },
      {
        path: "/your-funded-homes",
        component: () => import("@/views/YourFundedHomes.vue"),
      },
      {
        path: "/your-account",
        component: () => import("@/views/YourAccount.vue"),
      },
      {
        path: "/dashboard",
        meta: { requiresLogin: true },
        component: () => import("@/views/Dashboard.vue"),
      },

      {
        path: "/list-your-offering/overview/:id",
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        component: () =>
          import("@/views/listYourProperty/ListYourPropSteps.vue"),
        beforeEnter(to, from, next) {
          if (store.getters["user/is_authenticated"] === false) {
            store.commit("toggleLoginModal");
          } else {
            next();
          }
        },
      },

      {
        path: "/list-your-offering/tell-us-about-yourself/:id",
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        component: () =>
          import("@/views/listYourProperty/ListYourPropTellUs.vue"),
      },
      {
        path: "/list-your-offering/property-address/:id",
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        component: () =>
          import("@/views/listYourProperty/ListYourPropAddress.vue"),
      },
      {
        path: "/list-your-offering/build-listing/:id",
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        component: () =>
          import("@/views/listYourProperty/ListYourPropBuildListing.vue"),
      },
      {
        path: "/list-your-offering/determine-equity/:id",
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        component: () =>
          import("@/views/listYourProperty/ListYourPropDetermineEquity.vue"),
      },
      {
        path: "/list-your-offering/propose-terms/:id",
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        component: () =>
          import("@/views/listYourProperty/ListYourPropProposeTerms.vue"),
      },
      {
        path: "/list-your-offering/review/:id",
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        component: () =>
          import("@/views/listYourProperty/ListYourPropReview.vue"),
      },

      {
        path: "/list-your-offering/ready/:id",
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        component: () =>
          import("@/views/listYourProperty/ListYourPropSubmitted.vue"),
      },

      // {
      //   path: "/list-your-offering/ready/:id",
      //   props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
      //   component: () =>
      //     import("@/views/listYourProperty/ListYourPropReady.vue"),
      // },

      // {
      //   path: "/list-your-offering/review-and-publish/:id",
      //   props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
      //   component: () =>
      //     import("@/views/listYourProperty/ListYourPropReviewAndPubulish.vue"),
      // },

      {
        path: "/application-portal",
        component: () => import("@/views/application/ApplicationPortal.vue"),
        children: [
          {
            path: "",
            component: () =>
              import("@/views/application/ApplicationPortalOverview.vue"),
          },
          {
            path: "personal-info",
            component: () =>
              import("@/views/application/ApplicationPortalPersonalInfo.vue"),
          },
          {
            path: "personal-info-2",
            component: () =>
              import("@/views/application/ApplicationPortalPersonalInfo2.vue"),
          },
          {
            path: "personal-info-3",
            component: () =>
              import("@/views/application/ApplicationPortalPersonalInfo3.vue"),
          },
          {
            path: "personal-info-4",
            component: () =>
              import("@/views/application/ApplicationPortalPersonalInfo4.vue"),
          },
          {
            path: "employment",
            component: () =>
              import("@/views/application/ApplicationPortalEmployment.vue"),
          },
          {
            path: "financial-profile",
            component: () =>
              import(
                "@/views/application/ApplicationPortalFinancialProfile.vue"
              ),
          },
          {
            path: "property-info",
            component: () =>
              import("@/views/application/ApplicationPortalPropertyInfo.vue"),
          },
          {
            path: "property-info-2",
            component: () =>
              import("@/views/application/ApplicationPortalPropertyInfo2.vue"),
          },
          {
            path: "property-info-3",
            component: () =>
              import("@/views/application/ApplicationPortalPropertyInfo3.vue"),
          },
          {
            path: "appraisal",
            component: () =>
              import("@/views/application/ApplicationPortalAppraisal.vue"),
          },
          {
            path: "money-allocation",
            component: () =>
              import(
                "@/views/application/ApplicationPortalMoneyAllocation.vue"
              ),
          },
          {
            path: "form-c",
            component: () =>
              import("@/views/application/ApplicationPortalFormC.vue"),
          },
          {
            path: "offering-details",
            component: () =>
              import(
                "@/views/application/ApplicationPortalOfferingDetails.vue"
              ),
          },
        ],
      },
      {
        path: "/add-bank-account",
        name: "Add bank account",
        component: () => import("@/views/AddBankAccount.vue"),
      },
      {
        path: "/profile",
        name: "Profile",
        meta: { requiresLogin: true },
        component: () => import("@/views/Profile2.vue"),
      },
      {
        path: "/cart",
        component: () => import("@/views/Cart.vue"),
      },
      {
        path: "/checkout",
        component: () => import("@/views/Checkout.vue"),
      },
      {
        path: "/reserve",
        component: () => import("@/views/Reservation.vue"),
      },
      {
        path: "/update-payment",
        component: () => import("@/views/PaymentUpdate.vue"),
      },
      {
        path: "/eversign",
        component: () => import("@/views/Eversign.vue"),
      },
      {
        path: "/portfolio",
        component: () => import("@/views/Portfolio.vue"),
        name: "Portfolio",
        meta: { requiresLogin: true },
      },
      {
        path: "/strategies",
        component: () => import("@/views/Strategies.vue"),
      },
      {
        path: "/strategies/update/:name",
        name: "UpdateStrategy",
        component: () => import("@/components/UpdateStrategy.vue"),
      },
      {
        path: "/create-strategy",
        name: "create-strategy",
        component: () => import("@/views/CreateStrategy.vue"),
        beforeEnter(to, from, next) {
          if (store.getters["user/is_authenticated"] === false) {
            next("/strategies");
          } else {
            next();
          }
        },
      },
      {
        path: "/favorites",
        component: () => import("@/views/Favorites.vue"),
        name: "Favorites",
      },
      {
        path: "/edit-profile",
        component: RouterGroup,
        children: [
          {
            path: "picture",
            component: () => import("@/views/EditProfilePicture.vue"),
          },
          {
            path: "dashboard",
            component: () =>
              import("@/components/dashboard/EditProfilePicture.vue"),
          },
        ],
      },
      {
        path: "/investor-calculators",
        name: "Calculators",
        component: () => import("@/views/InvestorCalculators.vue"),
      },
      {
        path: "/investments/:state/:city/:id/upload-pictures",
        component: () => import("@/views/UploadHomePicturesView.vue"),
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
      },
      //{
      //    path: '/messages',
      //    name: 'Messages',
      //    component: () => import('@/views/Messages.vue')
      //},
      //{
      //    path: '/messages/:id',
      //    name: 'UserMessages',
      //    props: true,
      //    component: () => import ('@/views/UserMessages.vue')
      //},
      {
        path: "/admin",
        component: () => import("@/views/admin/Admin.vue"),
        beforeEnter(to, from, next) {
          if (store.getters["user/user"].roles.indexOf("admin") === -1) {
            next("/home");
          } else {
            next();
          }
        },
        children: [
          {
            path: "",
            redirect: "users",
          },
          {
            path: "users",
            component: () => import("@/views/admin/AdminUsers.vue"),
          },
          {
            path: "investments",
            component: () => import("@/views/admin/AdminInvestments.vue"),
          },
          {
            path: "hpi",
            component: () => import("@/views/admin/AdminHPI.vue"),
          },
          {
            path: "homes",
            component: () => import("@/views/admin/AdminHomes.vue"),
          },
          {
            path: "emails",
            component: () => import("@/views/admin/AdminEmails.vue"),
          },
          {
            path: "export",
            component: () => import("@/views/admin/AdminExport.vue"),
          },
          {
            path: "import",
            component: () => import("@/views/admin/AdminImport.vue"),
          },
          {
            path: "import-investment",
            component: () => import("@/views/admin/AdminNorthCapital.vue"),
          },
          {
            path: "errors",
            component: () => import("@/views/admin/AdminErrors.vue"),
          },
          {
            path: "strategies",
            component: () => import("@/views/admin/AdminStrategies.vue"),
          },
          {
            path: "file-upload",
            component: () => import("@/views/admin/AdminFileUpload.vue"),
          },
          {
            path: "ga",
            component: () => import("@/views/admin/AdminGA.vue"),
          },
          {
            path: "memos",
            component: () => import("@/views/admin/AdminMemos.vue"),
          },
          {
            path: "companies",
            component: () => import("@/views/admin/AdminCompanies.vue"),
          },
          {
            path: "update-engagebay-data",
            component: () => import("@/views/admin/AdminUpdateEngagebay.vue"),
          },
        ],
      },
      {
        path: "/analytics/:id",
        component: () => import("@/views/tools/Analytics.vue"),
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
      },
      /* {
        path: '/tools',
        redirect: '/tools/offering-memo',
        beforeEnter(to, from, next) {
          if (store.getters['user/user'].roles.indexOf('admin') === -1) {
            next('/home');
          } else {
            next();
          }
        },
      },
      {
        path: '/tools/offering-memo',
        component: () => import('@/views/tools/Welcome.vue'),
        beforeEnter(to, from, next) {
          if (store.getters['user/user'].roles.indexOf('admin') === -1) {
            next('/home');
          } else {
            next();
          }
        },
      },
      {
        path: '/tools/dashboard',
        redirect: '/tools/dashboard/my-memorandums',
        beforeEnter(to, from, next) {
          if (store.getters['user/user'].roles.indexOf('admin') === -1) {
            next('/home');
          } else {
            next();
          }
        },
      },
      {
        path: '/tools/dashboard/:tab',
        component: () => import('@/views/tools/Dashboard.vue'),
        props: ({ params }) => ({ currentPage: params.tab }),
        beforeEnter(to, from, next) {
          if (store.getters['user/user'].roles.indexOf('admin') === -1) {
            next('/home');
          } else {
            next();
          }
        },
      },
      {
        path: '/tools/memorandum-overview/:id',
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        component: () => import('@/views/tools/MemorandumOverview.vue'),
        beforeEnter(to, from, next) {
          if (store.getters['user/user'].roles.indexOf('admin') === -1) {
            next('/home');
          } else {
            next();
          }
        },
      },
      {
        path: '/tools/memorandum-overview/property-address/:id',
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        component: () => import('@/views/tools/PropertyAddress.vue'),
        beforeEnter(to, from, next) {
          if (store.getters['user/user'].roles.indexOf('admin') === -1) {
            next('/home');
          } else {
            next();
          }
        },
      },
      {
        path: '/tools/memorandum-overview/tell-us/:id',
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        name: 'about-you',
        component: () => import('@/views/tools/TellUsAboutYourself.vue'),
        beforeEnter(to, from, next) {
          if (store.getters['user/user'].roles.indexOf('admin') === -1) {
            next('/home');
          } else {
            next();
          }
        },
      },
      {
        path: '/tools/memorandum-overview/build-offering/:id',
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        component: () => import('@/views/tools/BuildOffering.vue'),
        beforeEnter(to, from, next) {
          if (store.getters['user/user'].roles.indexOf('admin') === -1) {
            next('/home');
          } else {
            next();
          }
        },
      },
      {
        path: '/tools/memorandum-overview/choose-plan/:id',
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        component: () => import('@/views/tools/ChoosePlan.vue'),
        beforeEnter(to, from, next) {
          if (store.getters['user/user'].roles.indexOf('admin') === -1) {
            next('/home');
          } else {
            next();
          }
        },
      },
      {
        path: '/tools/public-profile/:id',
        component: () => import('@/views/tools/PublicProfile.vue'),
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        beforeEnter(to, from, next) {
          if (store.getters['user/user'].roles.indexOf('admin') === -1) {
            next('/home');
          } else {
            next();
          }
        },
      },
      {
        path: '/tools/public-company-profile/:id',
        component: () => import('@/views/tools/CompanyPublicProfile.vue'),
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        beforeEnter(to, from, next) {
          if (store.getters['user/user'].roles.indexOf('admin') === -1) {
            next('/home');
          } else {
            next();
          }
        },
      },
      {
        path: '/tools/memorandum-overview/summary/:id',
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        component: () => import('@/views/tools/Summary.vue'),
        beforeEnter(to, from, next) {
          if (store.getters['user/user'].roles.indexOf('admin') === -1) {
            next('/home');
          } else {
            next();
          }
        },
      },
      {
        path: '/tools/listing/:id',
        props: ({ params }) => ({ id: Number.parseInt(params.id, 10) || 0 }),
        component: () => import('@/views/tools/Listing.vue'),
        beforeEnter(to, from, next) {
          if (store.getters['user/user'].roles.indexOf('admin') === -1) {
            next('/home');
          } else {
            next();
          }
        },
      }, */
      {
        path: "/*",
        component: () => import("@/views/NotFound.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (store.getters["user/is_authenticated"] === false) {
      next("/signin");
      // store.commit('toggleLoginModal');
    } else {
      next();
    }
  } else {
    next();
  }
});

// Function to handle NavigationDuplicated error
const handleNavigationError = (error) => {
  if (error.name === 'NavigationDuplicated') {
    // Handle the error here, for example, by showing a message to the user
    
  } else {
    // If it's not a NavigationDuplicated error, throw it again to be handled elsewhere
    throw error
  }
}

// Override the push method of the router to catch errors
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(handleNavigationError)
}

// Override the replace method of the router to catch errors
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(handleNavigationError)
}

export default router;
