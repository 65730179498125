import Vue from "vue";
import backend from "@/services/backend";

export default {
  namespaced: true,
  state: {
    notifications: [],
    since: 0,
  },
  mutations: {
    set_notifications: function (state, val) {
      state.notifications = val;
    },
    set_since: function (state, val) {
      state.since = val;
    },
  },
  getters: {
    notifications: (state) => {
      return state.notifications;
    },
    unseen_notifications_length: (state) => {
      return state.notifications.filter((x) => !x.seen).length;
    },
  },
  actions: {
    push_notifications(context, val) {
      // Create new notification list
      let n = [];

      // Add new notifications
      val.forEach((x) => {
        n.push(x);

        if (x.name === "account_confirmed") {
          context.commit("user/set_confirmed", x.data, { root: true });
        }
      });

      // Add back old notifications
      context.state.notifications.forEach((x) => {
        n.push(x);
      });

      // And set the new notification list
      context.commit("set_notifications", n);
    },
    fetch_notifications(context) {
      return backend.get_notifications(context.state.since).then((response) => {
        let notifications = response.data;
        context.dispatch("push_notifications", notifications);
        if (notifications.length)
          //context.commit('set_since', notifications.slice(-1)[0].timestamp)
          context.commit("set_since", notifications[0].timestamp);
      });
    },
    notification_seen(context, notification_id) {
      return backend.notification_seen(notification_id).then((response) => {
        let n = context.state.notifications.find(
          (x) => x.id === notification_id
        );
        if (n) {
          n.seen = true;
        }
      });
    },
  },
};
