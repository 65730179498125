import { maybe_ignore_error, ignore_redirect } from "./error.js";
import home_utils from "./home.js";
import facebook from "./facebook.js";

export default {
  install(Vue, options) {
    Vue.prototype.$utils = {
      home: home_utils,
      maybe_ignore_error,
      ignore_redirect,
      facebook,

      format_key(k) {
        let v = k.replace(/_/g, " ");
        return v.charAt(0).toUpperCase() + v.slice(1);
      },

      getCloudinaryMedia(url) {
        if (url) {
          const pathList = url.split("/");
          const lastSegment = pathList.pop();
          const bucketName = pathList.pop();
          return `${bucketName + "/" + lastSegment}`;
        }
      },

      format_number(val) {
        return val ? val.toLocaleString() : "";
      },

      format_dollar(val) {
        return val !== null ? "$" + val.toLocaleString() : "";
      },

      alert(msg) {
        alert(msg);
      },

      to_title_case(str, cap_all = true) {
        let s = str.replace(/_/g, " ");
        if (cap_all)
          return s.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
        return s.charAt(0).toUpperCase() + s.substr(1);
      },

      format_address(address) {
        //  return `${address.formatted_street_address || address.street}, ${address.city}, ${address.state} ${address.zip_code || address.zipcode}`
      },

      truncate(str, n) {
        return str.length > n ? str.substr(0, n - 1) + "..." : str;
      },

      load_script(src, id = "") {
        return new Promise(function (resolve, reject) {
          if (document.querySelector('script[src="' + src + '"]')) {
            resolve();
            return;
          }
          const el = document.createElement("script");
          el.type = "text/javascript";
          el.async = true;
          el.src = src;
          el.id = id;
          el.addEventListener("load", resolve);
          el.addEventListener("error", reject);
          el.addEventListener("abort", reject);
          document.head.appendChild(el);
        });
      },
      remove_script(id) {
        let script = document.getElementById(id);
        if (script) script.parentNode.removeChild(script);
      },

      url_for_home(home) {
        let state = home.state.toLowerCase();
        let city = home.city.toLowerCase().replace(/\s+/g, "-");
        return `/investments/${state}/${city}/${home.id}`;
      },

      parse_google_place(place) {
        const componentForm = {
          street_number: "short_name",
          route: "long_name",
          locality: "long_name",
          administrative_area_level_1: "short_name",
          country: "long_name",
          postal_code: "short_name",
        };

        let result = {
          administrative_area_level_1: "",
          locality: "",
          route: "",
          street_number: "",
          postal_code: "",
        };

        for (const component of place.address_components) {
          const addressType = component.types[0];

          if (componentForm[addressType]) {
            const val = component[componentForm[addressType]];
            result[addressType] = val;
          }
        }
        let street = [result["street_number"], result["route"]].join(" ");

        return {
          street: street,
          city: result["locality"] || result["administrative_area_level_1"],
          state: result["administrative_area_level_1"],
          zipcode: result["postal_code"],
        };
      },
    };
  },
};
