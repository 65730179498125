<template>
  <div class="">
    <div class="mt-4">
      <span v-for="(item, i) in breadcrumData" :key="i">
        <router-link
          v-if="item.route_path !== 'none'"
          :to="item.route_path"
          class="text-primary"
          >{{ $t(item.title) }}</router-link
        >
        <a v-else class="breadcum-text-color text-decoration-none">{{
          $t(item.title)
        }}</a>
        <span
          v-if="item.route_path !== 'none'"
          class="ml-2 mr-2 mt-2 breadcum-text-color"
          >/</span
        >
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    input: { type: Array, required: false, default: [] },
  },
  data() {
    return {};
  },
  computed: {
    breadcrumData: function () {
      return this.input;
    },
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped>
.breadcum-text-color {
  color: #aaaaaa;
}
.breadcum-area {
  background-color: #ffffff !important;
}
</style>
