import axios from "axios";

export default {
  getsppx(data) {
    return axios.post("/api/get-sppx-user-profile", data);
  },  
  updateprofilesppx(data) {
    return axios.post("/api/update-sppx-user-profile", data);
  },  
  refreshsppx(data) {
    return axios.post("/api/sppx-refresh-token", data);
  },  
  signinsppx(data) {
    return axios.post("/api/sppx-login", data);
  },
  signin(data) {
    return axios.post("/api/signin", data);
  },
  signup(data) {
    return axios.post("/api/signup", data);
  },
  signout() {
    return axios.post("/api/signout");
  },
  me() {
    return axios.get("/api/me");
  },
  get_accreditation_letter(){
    return axios.post("/api/get-accreditation-letter");
  },
  accept_legal_disclosures_for_investor() {
    return axios.post("/api/accept-legal-disclosures-for-investor");
  },

  get_notifications(since) {
    return axios.get("/api/notifications", { params: { since: since } });
  },
  notification_seen(notification_id) {
    return axios.post("/api/notification-seen", {
      notification: notification_id,
    });
  },

  get_home(id, name) {
    return axios.get("/api/homes/get", {
      params: { home_id: id, home_name: name },
    });
  },
  like_home(id) {
    return axios.post("/api/homes/like", { home: id });
  },
  unlike_home(id) {
    return axios.post("/api/homes/unlike", { home: id });
  },
  get_listings() {
    return axios.get("/api/homes/get-listings");
  },
  search_listings(query) {
    return axios.get("/api/homes/search-listings", { params: query });
  },
  get_homes(ids) {
    return axios.post("/api/homes/get-many", { homes: ids });
  },
  update_home(data) {
    return axios.post("/api/homes/update", data);
  },
  update_home_step(data) {
    return axios.post("/api/homes/update-step", data);
  },

  update_user(data) {
    return axios.post("/api/update-user", data);
  },
  update_accreditation(data) {
    return axios.post("/api/update-accreditation", data);
  }, 
  add_home(data) {
    return axios.post("/api/homes/create", data);
  },

  add_home_activity(data) {
    return axios.post("/api/homes/add-activity", data);
  },

  get_users(query) {
    return axios.get("/api/get-users", { params: query });
  },
  get_investments(query) {
    return axios.get("/api/admin-get-investments", { params: query });
  },
  update_investment_status(data) {  
    return axios.post("/api/update-transaction-status", data);
  },
  get_user(user_id) {
    return axios.get("/api/users/get", { params: { user_id: user_id } });
  },

  add_user(data) {
    return axios.post("/api/add-user", data);
  },
  delete_user(user_id) {
    return axios.post("/api/delete-user", { id: user_id });
  },

  update_profile(data) {
    return axios.post("/api/update-profile", data);
  },

  upload_profile_picture(formData) {
    axios.post("/api/update-profile-picture", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  update_investor_profile(data) {
    return axios.post("/api/update-investor-profile", data);
  },
  delete_profile_picture() {
    return axios.post("/api/delete-profile-picture");
  },

  send_emails(data) {
    return axios.post("/api/send-emails", data);
  },

  get_cart() {
    return axios.get("/api/get-cart");
  },
  to_cart(data) {
    return axios.post("/api/to-cart", data);
  },
  update_cart_amount(data) {
    return axios.post("/api/update-cart-amount", data);
  },
  admin_investment_home_dropdown() {
    return axios.get("/api/admin-investment-home-dropdown");
  },
  remove_from_cart(data) {
    return axios.post("/api/remove-from-cart", data);
  },
  submit_cart(data) {
    return axios.post("/api/submit-cart", data);
  },

  cancel_investment(data) {
    return axios.post("/api/cancel-investment", data);
  },

  get_portfolio() {
    return axios.get("/api/get-portfolio");
  },

  get_user_homes() {
    return axios.get("/api/get-user-homes");
  },

  /* get_investments() {
       return axios.get("/api/get-investments");
     },*/
  get_messages() {
    return axios.get("/api/get-messages");
  },
  send_message(recipient_id, text) {
    return axios.post("/api/send-message", {
      recipient: recipient_id,
      message: text,
    });
  },

  message_home(home_id, message, reply_to, disclosure) {
    return axios.post("/api/homes/message", {
      home: home_id,
      message: message,
      reply_to: reply_to,
      disclosure: disclosure,
    });
  },
  get_home_messages(home_id) {
    return axios.get("/api/homes/get-messages", { params: { home: home_id } });
  },
  vote_home_message(message_id) {
    return axios.post("/api/homes/vote-message", { home_message: message_id });
  },

  admin_delete_home(home_id) {
    return axios.post("/api/admin-delete-home", { home: home_id });
  },

  admin_delete_message(message_id) {
    return axios.post("/api/admin-delete-message", { message_id: message_id });
  },

  delete_home(home_id) {
    return axios.post("/api/homes/delete", { home: home_id });
  },

  admin_get_homes(query) {
    return axios.get("/api/admin-get-homes", { params: query });
  },
  admin_get_memos(query) {
    return axios.post("/api/admin-get-memos", query);
  },
  confirm_account(token) {
    return axios.post("/api/confirm/" + token);
  },

  admin_import_nc_investment(data) {
    return axios.post("/api/import-nc-investment", data);
  },

  admin_update_transaction_data(data) {
    return axios.post("/api/update-transaction-data", data);
  },

  admin_update_trade_status(data) {
    return axios.post("/api/update-trade-status", data);
  },

  admin_update_investment_account(data) {
    return axios.post("/api/update-investment-account", data);
  },

  admin_get_investment_activity(home_id) {
    return axios.get("/api/get-investment-activity", {
      params: { home: home_id },
    });
  },

  request_password_reset(email) {
    return axios.post("/api/reset-password", { email: email });
  },
  reset_password(token, new_password) {
    return axios.post("/api/reset/" + token, { password: new_password });
  },

  change_password(old_password, new_password) {
    return axios.post("/api/change-password", {
      old_password: old_password,
      new_password: new_password,
    });
  },

  cancel_issuing(home_id) {
    return axios.post("/api/cancel-issuing", { home: home_id });
  },

  get_hpi_zip() {
    return axios.get("/api/update-hpi-zip");
  },

  update_hpi_zip() {
    return axios.post("/api/update-hpi-zip");
  },

  create_entity(data) {
    return axios.post("/api/create-entity", data);
  },
  create_entity_from_profile() {
    return axios.post("/api/create-entity-from-profile");
  },
  create_associated_person(data) {
    return axios.post("/api/create-associated-person", data);
  },
  create_entity_relationship(data) {
    return axios.post("/api/create-entity-relationship", data);
  },
  create_investor(data) {
    return axios.post("/api/create-investor", data);
  },
  create_subscription_agreement(data) {
    return axios.post("/api/create-subscription-agreement", data);
  },
  validate_investment(data) {
    return axios.post("/api/validate-investment", data);
  },
  sign_subscription_agreement(data) {
    return axios.post("/api/sign-subscription-agreement", data);
  },

  create_party(data) {
    return axios.post("/api/create-party", data);
  },
  create_account(data) {
    return axios.post("/api/create-account", data);
  },
  create_issuer(data) {
    return axios.post("/api/create-issuer", data);
  },
  create_link(data) {
    return axios.post("/api/create-account-party-link", data);
  },
  get_external_plaid(data) {
    return axios.post("/api/get-external-account-plaid", data);
  },
  link_external_plaid(data) {
    return axios.post("/api/link-external-account-plaid", data);
  },
  update_external_plaid(data) {
    return axios.post("/api/update-link-external-account-plaid", data);
  },
  get_nc_party() {
    return axios.get(`/api/get-nc-party`);
  },
  delete_file_video(data){
    return axios.post("/api//homes/delete-file", data);
  },
  get_nc_account() {
    return axios.get(`/api/get-nc-account`);
  },

  nearby_offerings(home_id) {
    return axios.get("/api/nearby-offerings", { params: { home: home_id } });
  },

  offer_funding(home_id) {
    return axios.post("/api/homes/offer-funding", { home_id: home_id });
  },

  follow_user(user_id) {
    return axios.post("/api/users/follow", { user_id: user_id });
  },
  unfollow_user(user_id) {
    return axios.post("/api/users/unfollow", { user_id: user_id });
  },
  get_user_followers(user_id, limit = 15, top_id = null) {
    return axios.get("/api/users/get-followers", {
      params: { user_id: user_id, limit: limit, top_id: top_id },
    });
  },
  rate_user(user_id, score) {
    return axios.post("/api/users/rate", { user_id: user_id, score: score });
  },
  unrate_user(user_id) {
    return axios.post("/api/users/unrate", { user_id: user_id });
  },

  get_strategies(sort_by = "most_popular") {
    return axios.get("/api/get-strategies", { params: { sort_by: sort_by } });
  },
  get_strategy_by_name(name) {
    return axios.get("/api/get-strategy-by-name", { params: { name: name } });
  },
  create_strategy(data) {
    return axios.post("/api/create-strategy", data);
  },
  update_strategy(data) {
    return axios.post("/api/update-strategy", data);
  },
  delete_strategy(id) {
    return axios.post("/api/delete-strategy", { id: id });
  },
  follow_strategy(id) {
    return axios.post("/api/follow-strategy", { id: id });
  },
  unfollow_strategy(id) {
    return axios.post("/api/unfollow-strategy", { id: id });
  },
  get_home_strategies(home_id) {
    return axios.get("/api/get-home-strategies", { params: { home: home_id } });
  },
  set_home_strategies(home_id, strategy_ids) {
    return axios.post("/api/set-home-strategies", {
      home: home_id,
      strategies: strategy_ids,
    });
  },

  get_investors_for_home(home_id) {
    return axios.get("/api/get-investors-for-home", {
      params: { home: home_id },
    });
  },

  get_owner_homes(owner_id) {
    return axios.get("/api/get-owner-homes", {
      params: { owner_id: owner_id },
    });
  },

  search(category, query) {
    return axios.get("/api/search", {
      params: { category: category, query: query },
    });
  },

  login_as(user_id) {
    return axios.post("/api/login-as", { user: user_id });
  },

  upload_file(formdata) {
    return axios.post("/api/upload-file", formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  get_home_documents(home_id) {
    return axios.get("/api/homes/get-documents", {
      params: { home_id: home_id },
    });
  },
  upload_home_document(home_id, name, file) {
    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("data", JSON.stringify({ home_id: home_id, name: name }));
    return axios.post("/api/homes/upload-document", formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  upload_home_picture(home_id, file, pos) {
    let formdata = new FormData();
    formdata.append("data", JSON.stringify({ home_id: home_id }));
    formdata.append("file", file);
    formdata.append("pos", pos);
    return axios.post("/api/homes/upload-picture", formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  check_available_name(name) {
    return axios.post("/api/homes/check-available-name", { name: name });
  },

  update_home_picture(id, pos, url) {
    return axios.post("/api/homes/update-picture", {
      pos: pos,
      id: id,
      url: url,
    });
  },

  delete_home_picture(url) {
    axios.post("/api/homes/delete-picture", {
      url: url,
    });
  },
  delete_home_document(document_id) {
    return axios.post("/api/homes/delete-document", {
      document_id: document_id,
    });
  },

  calculate_returns_option_repurchase(data) {
    return axios.get("/api/calculate-returns-option-repurchase", {
      params: data,
    });
  },

  create_post(data, img, vid, id) {
    let formdata = new FormData();
    formdata.append(
      "data",
      JSON.stringify({
        text: data,
        allow_likes: true,
        allow_replies: true,
        activity_id: id,
      })
    );

    formdata.append("image.0", img);

    formdata.append("video.0", vid);

    return axios.post("/api/posts/create", formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  like_activity(id) {
    return axios.post("/api/activities/like", { activity_id: id });
  },
  unlike_activity(id) {
    return axios.post("/api/activities/unlike", { activity_id: id });
  },
  get_feed(limit = 15, top_id = null) {
    return axios.get("/api/activities/get-feed", {
      params: { limit: limit, top_id: top_id },
    });
  },
  get_activity_replies(activity_id, limit = 15, top_id = null) {
    console.trace();
    return axios.get("/api/activities/get-children", {
      params: {
        activity_id: activity_id,
        typ: "post",
        limit: limit,
        top_id: top_id,
      },
    });
  },
  /* get_post(id) {
        return axios.get("/api/posts/get")
      }
      */

  /* Memo querying functions */
  memo_get(memo_id) {
    return axios.get("/api/memo/get", {
      params: { memo_id: memo_id },
    });
  },
  memo_all(user_id) {
    return axios.get("/api/memo/all", {
      params: { user_id: user_id },
    });
  },

  /* Memo building functions */
  memo_new(context = null) {
    return axios.post("/api/memo/new", { context: context });
  },
  memo_set_context(memo_id, context = null) {
    return axios.post("/api/memo/set-context", {
      memo_id: memo_id,
      context: context,
    });
  },
  memo_set_address(
    memo_id,
    street_address /* string */,
    city /* string */,
    state /* string */,
    zip_code /* string */,
    context = null
  ) {
    return axios.post("/api/memo/set-address", {
      memo_id: memo_id,
      street_address: street_address,
      city: city,
      state: state,
      zip_code: zip_code,
      context: context,
    });
  },
  memo_set_about_info(memo_id, about_info /* json */, context = null) {
    return axios.post("/api/memo/set-about-info", {
      memo_id: memo_id,
      info: about_info,
      context: context,
    });
  },
  memo_set_offering_info(memo_id, offering_info /* json */, context = null) {
    return axios.post("/api/memo/set-offering-info", {
      memo_id: memo_id,
      info: offering_info,
      context: context,
    });
  },
  memo_upload_files(memo_id, namespace, files, context = null) {
    let formdata = new FormData();
    for (let name in files) {
      formdata.append(name, files[name]);
    }
    const args = {
      memo_id: memo_id,
      namespace: namespace,
      context: context,
    };
    formdata.append("data", JSON.stringify(args));
    return axios.post("/api/memo/upload-files", formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  memo_upload_offering_images(memo_id, namespace, images, context = null) {
    let formdata = new FormData();
    images.forEach((image) => {
      formdata.append(image.name, image);
    });
    const args = {
      memo_id: memo_id,
      namespace: namespace,
      context: context,
    };
    formdata.append("data", JSON.stringify(args));
    return axios.post("/api/memo/upload-files", formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  memo_upload_about_files(memo_id, files, context = null) {
    return this.memo_upload_files(memo_id, "about", files, context);
  },
  memo_upload_offering_files(memo_id, files, context = null) {
    this.memo_upload_files(memo_id, "offering", files, context);
  },
  memo_delete_file(memo_id, name, namespace, context = null) {
    return axios.post("/api/memo/delete-file", {
      memo_id: memo_id,
      name: name,
      namespace: namespace,
      context: context,
    });
  },
  memo_subscribe(
    memo_id,
    subscription /* ['memo_monthly', 'memo_yearly'] */,
    context = null
  ) {
    return axios.post("/api/memo/subscribe", {
      memo_id: memo_id,
      subscription: subscription,
      context: context,
    });
  },
  memo_set_status(
    memo_id,
    status /* ['draft', 'completed', 'archived'] */,
    context = null
  ) {
    return axios.post("/api/memo/set-status", {
      memo_id: memo_id,
      status: status,
      context: context,
    });
  },
  memo_complete(memo_id, context = null) {
    return this.memo_set_status(memo_id, "completed", context);
  },
  memo_delete(memo_id) {
    return axios.post("/api/memo/delete", {
      memo_id: memo_id,
    });
  },
  memo_create_update(text, memo_id) {
    return axios.post("/api/memo/create-update", {
      text: text,
      memo_id: memo_id,
    });
  },
  memo_add_notification(memo_id, cta) {
    return axios.post("/api/memo/add-notification", {
      memo_id: memo_id,
      cta: cta,
    });
  },

  memo_get_notifications(memo_id) {
    return axios.get(`/api/memo/get-notifications?memo_id=${memo_id}`);
  },

  memo_get_token(memo_id) {
    return axios.get("/api/memo/get-memo-token", { memo_id: memo_id });
  },

  memo_get_by_token(token, firstname, lastname, email) {
    return axios.post("/api/memo/get-memo-by-token", {
      memo_token: token,
      first_name: firstname,
      last_name: lastname,
      email: email,
    });
  },

  memo_visit(memo_id, firstname, lastname, email) {
    return axios.post("/api/memo/visit", {
      memo_id: memo_id,
      first_name: firstname,
      last_name: lastname,
      email: email,
    });
  },

  get_ga_reports(requests) {
    return axios.post("/api/get-ga-reports", { reportRequests: requests });
  },

  get_company(id) {
    return axios.get(`/api/company-get?id=${id}`);
  },

  get_companies(query) {
    return axios.get("/api/companies/all", { params: query });
  },

  company_create(data, id) {
    return axios.post("/api/companies/create", {
      company_id: id,
      name: data.name,
      primary_color: data.primary_color,
      description: data.description,
      country: data.country,
      city: data.city,
      position: data.position,
      tenure: data.tenure,
      state: data.state,
      url: data.url,
      bio: data.bio,
      logo: data.logo,
      ein: data.EIN,
    });
  },
  company_update(data, id) {
    // return axios.post("/api/companies/update", {
    //   company_id: id,
    //   name: data.name,
    //   name_506c: data.name_506c,
    //   primary_color: data.primary_color,
    //   description: data.description,
    //   country: data.country,
    //   city: data.city,
    //   position: data.position,
    //   tenure: data.tenure,
    //   state: data.state,
    //   url: data.url,
    //   bio: data.bio,
    //   logo: data.logo,
    //   ein: data.ein,
    //   address: data.address,
    //   formation_date: data.formation_date,
    //   jurisdiction: data.jurisdiction,
    //   number_employees: data.number_employees,
    //   zipcode: data.zipcode,
    //   company_type: data.company_type,
    //   fiscal_year_end: data.fiscal_year_end,
    //   webhook_url:data.webhook_url,
    //   webhook_service_enabled:data.webhook_service_enabled
    // });

    const payload = {
      company_id: id,
      name: data.name,
      name_506c: data.name_506c,
      primary_color: data.primary_color,
      description: data.description,
      country: data.country,
      city: data.city,
      position: data.position,
      tenure: data.tenure,
      state: data.state,
      url: data.url,
      bio: data.bio,
      logo: data.logo,
      ein: data.ein,
      address: data.address,
      formation_date: data.formation_date,
      jurisdiction: data.jurisdiction,
      number_employees: data.number_employees,
      zipcode: data.zipcode,
      company_type: data.company_type,
      fiscal_year_end: data.fiscal_year_end
    };

    if (data.custom_webhook) {
      payload.webhook_url = data.webhook_url;
      payload.webhook_service_enabled = data.webhook_service_enabled;
    }

    return axios.post("/api/companies/update", payload);
  },

  company_add_officers(data) {
    return axios.post("/api/companies/add-officers", data);
  },

  company_update_officer(data) {
    return axios.post("/api/companies/update-officer", {
      first_name: data.first_name,
      last_name: data.last_name,
      position: data.position,
      tenure: data.tenure,
      previous_employers: data.previous_employers,
      officer_id: data.id,
      linkedin: data.linkedin,
    });
  },

  company_delete_officer(id, url) {
    return axios.post("/api/companies/delete-officer", {
      officer_id: id,
      url: url,
    });
  },

  company_delete_picture(data) {
    return axios.post("/api/companies/delete-officer-picture", {
      officer_id: data.officer_id,
      url: data.url,
    });
  },
  company_update_picture(data) {
    return axios.post("/api/companies/update-officer-picture", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  company_invite(id, data) {
    return axios.post("/api/companies/invite", {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      position: data.position,
      tenure: data.tenure,
      picture: data.picture,
      company_id: id,
    });
  },

  remove_company_user(company_id, user_id) {
    return axios.post("/api/companies/remove-user", {
      company_id: company_id,
      user_id: user_id,
    });
  },

  invited(token, password) {
    return axios.post("/api/invited", { token: token, password: password });
  },

  set_regulation(regulation, id) {
    return axios.post("/api/memo/set-regulation", {
      memo_id: id,
      regulation: regulation,
    });
  },

  get_memo_visits(memo_id) {
    return axios.get(`/api/memo/get-visits?memo_id=${memo_id}`);
  },

  get_investor_id() {
    return axios.get(`/api/get-fa-entity-id`);
  },

  process_reservations(data) {
    return axios.post("/api/process-reservations", data);
  },

  checkout_investments(data) {
    return axios.post("/api/checkout-investments", data);
  },
  verify_bank_account(data) {
    return axios.post("/api/verify-bank-account", data);
  },
  reserve_investment(data) {
    return axios.post("/api/reserve-investment", data);
  },

  update_payment_method(data) {
    return axios.post("/api/update-payment-method", data);
  },

  create_offering(data) {
    return axios.post("/api/create-offering-from-home", data);
  },

  create_ach_authorization(data) {
    return axios.post("/api/create-ach-authorization", data);
  },

  perform_kyc_aml_check(data) {
    return axios.post("/api/perform-kyc-aml-check", data);
  },
  sppx_ach_bank(data) {
    return axios.post("/api/sppx-ach-bank", data);
  },
  sppx_ach_bank_list(data) {
    return axios.post("/api/sppx-ach-bank-list", data);
  },
  upload_party_document(data) {
    return axios.post("/api/upload-party-document", data);
  },

  send_kyc_email(data) {
    return axios.post("/api/send-kyc-email", data);
  },

  perform_accreditation_check(data) {
    return axios.post("/api/perform-accreditation-check", data);
  },

  upload_accreditation_document(data) {
    return axios.post("/api/upload-accreditation-document", data);
  },

  update_investment_type(data) {
    return axios.post("/api/update-investment-type", data);
  },

  get_otp_secret() {
    return axios.get("/api/get-otp-secret");
  },

  get_otp_uri() {
    return axios.get("/api/get-otp-uri");
  },

  enable_otp(data) {
    return axios.post("/api/enable-otp", data);
  },

  send_otp_code() {
    return axios.post("/api/send-otp-code");
  },
  get_accreditation() {
    return axios.post("/api/perform-accreditation-status-request");
  },
  create_custom_tab(data) {
    return axios.post("/api/homes/add-tab", data);
  },
  get_custom_tabs(home_id) {
    return axios.get("/api/homes/get-tab", { params: { home: home_id } });
  },
  edit_custom_tab(data){
    return axios.post("/api/homes/update-tab", data);
  },
  delete_custom_tab(data){
    return axios.post("/api/homes/delete-tab", data);
  },
  upload_file_url(){
    return axios.get("/api/generate-upload-file-url");
  },
  escrow_create(data) {
    return axios.post("/api/escrow-selection", data);
  },
  create_plaid_link(){
    return axios.post("/api/plaid-create-link-token");
  },
  plaid_set_access_token(data){
    return axios.post("/api/plaid-set-access-token",data);
  },
  get_account_details(data){
    return axios.post("/api/get-account-details",data);
  },
  identity_verification(data){
    return axios.post("/api/identity-verification",data);
  },
  get_identity_verification(){
    return axios.post("/api/get-identity-verification");
  },
  get_identity_verification_init(){
    return axios.post("/api/get-identity-verification-init");
  }
};