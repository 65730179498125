import moment from "moment";

export default {
  investment_maturity(home) {
    return new Date().getFullYear() + home.term_of_investment;
  },
  ltv(home) {
    return (
      Math.round(
        (10000 * (home.debt + home.additional_debt)) / home.valuation
      ) / 100
    );
  },
  dividend_range(home) {
    return {
      min: home.calculator_results.Dividend_Schedule.reduce((prev, curr) =>
        prev.Dividend < curr.Dividend ? prev : curr
      ).Dividend,
      max: home.calculator_results.Dividend_Schedule.reduce((prev, curr) =>
        prev.Dividend < curr.Dividend ? curr : prev
      ).Dividend,
    };
  },
  funding_round_days_left(home) {
    return moment(home.funding_round_end).diff(moment.utc(), "days");
  },
};
