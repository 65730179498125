// Remove? might not be using anymroe

import Vue from "vue";
import backend from "@/services/backend";

function get_investor_steps(context) {
  let s = [
    {
      title: "Address",
      component: "ProfileAddress",
      submit: () => {
        return backend
          .update_profile(context.state.investor.address)
          .then((response) => {
            context.commit(
              "user/set_user_data",
              context.state.investor.address,
              { root: true }
            );
          });
      },
      hide_next_button: true,
      check() {
        return context.rootGetters["user/user"].street;
      },
    },
    {
      title: "Identity",
      component: "ProfileIdentity",
      submit: () => {
        let data = {
          birthdate: context.state.investor.birthdate,
          ssn: context.state.investor.ssn,
        };
        return backend.update_profile(data).then((response) => {
          context.commit("user/set_user_data", data, { root: true });
        });
      },
      hide_next_button: true,
      check() {
        let user = context.rootGetters["user/user"];
        return user.birthdate && (user.ssn_masked || user.ssn);
      },
    },
    {
      title: "Citizenship",
      component: "InvestorCitizen",
      submit: () => Promise.resolve(),
      check() {
        return context.rootGetters["user/user"].citizen;
      },
    },
    {
      title: "Questionnaire",
      component: "InvestorLegal",
      hide_next_button: true,
      submit: () => {
        if (!context.state.investor.questionnaire_completed) {
          context.commit("push_error", "Must accept all terms", { root: true });
          return Promise.reject();
        }
        return backend
          .accept_legal_disclosures_for_investor()
          .then((response) => {
            context.commit(
              "user/set_investor_data",
              { legal_disclosures_accepted: true },
              { root: true }
            );
          });
      },
      check() {
        let ip = context.rootGetters["user/user"].investor_profile;
        return ip && ip.legal_disclosures_accepted;
      },
    },
  ].filter((x) => !x.check());

  if (s.length > 0) {
    s.push({
      title: "Link your bank account",
      component: "InvestorFundYourAccount",
      submit: () => Promise.resolve(),
      check() {
        return false;
      },
    }),
      s.push({
        title: "Done",
        component: "InvestorDone",
        hide_next_button: true,
        submit: () => Promise.resolve(),
        check() {
          return false;
        },
      });
  }

  return s;
}

export default {
  namespaced: true,
  state: {
    steps: [],
    current: null,
    investor: {
      address: {},
      birthdate: "",
      ssn: "",
      citizen: null,
      terms: {
        first: false,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
      },
      questionnaire_completed: false,
    },
  },
  getters: {
    next_step(state) {
      let i = state.steps.indexOf(state.current) + 1;
      if (i < state.steps.length) return state.steps[i];
      return null;
    },
    previous_step(state) {
      let i = state.steps.indexOf(state.current) - 1;
      if (i >= 0) return state.steps[i];
      return null;
    },
    current_step(state) {
      return state.current;
    },
    completed(state) {
      return state.steps.indexOf(state.current) + 1;
    },
    steps(state) {
      return state.steps;
    },
    profile_complete(state, getters, rootState, rootGetters) {
      return (
        !rootGetters["user/is_authenticated"] ||
        get_investor_steps({ rootGetters }).length === 0
      );
    },
  },
  actions: {
    async calc_steps(context) {
      if (
        context.rootGetters["user/user"].investor_profile === null ||
        !context.rootGetters["user/is_investor"]
      ) {
        let response = await backend.create_investor_profile();
        context.commit(
          "user/set_user_data",
          {
            roles: ["investor", ...context.rootGetters["user/user"].roles],
            investor_profile: response.data,
          },
          { root: true }
        );
      }
      context.state.steps = get_investor_steps(context);
      context.state.current = context.state.steps[0];
    },
    async go_next(context) {
      try {
        await context.state.current.submit();
        context.state.current = context.getters.next_step;
      } catch {}
    },
    go_back(context) {
      if (context.getters.previous_step)
        context.state.current = context.getters.previous_step;
    },

    submit_citizen(context, val) {
      backend.update_profile({ citizen: val }).then((response) => {
        context.state.citizen = val;
        context.commit("user/set_user_data", { citizen: val }, { root: true });
        context.dispatch("go_next");
      });
    },
  },
};
