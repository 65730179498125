import Vue from "vue";
import backend from "@/services/backend";

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    set_user: function (state, val) {
      state.user = val;
    },
    set_user_data: function (state, data) {
      for (const key of Object.keys(data)) {
        Vue.set(state.user, key, data[key]);
      }
    },
    set_investor_data: function (state, data) {
      for (const key of Object.keys(data)) {
        Vue.set(state.user.investor_profile, key, data[key]);
      }
    },
    unset_user: function (state) {
      state.user = { authenticated: false, roles: [], homes: [] };
    },
    push_home(state, val) {
      state.user.homes.push(val);
    },
    push_bank_account(state, val) {
      state.user.bank_accounts.push(val);
    },
    push_home_like(state, home_id) {
      state.user.home_likes.push(home_id);
    },
    remove_home_like(state, home_id) {
      state.user.home_likes = state.user.home_likes.filter(
        (x) => x !== home_id
      );
    },
    set_confirmed(state, confirmed_at) {
      state.user.confirmed_at = confirmed_at;
    },
  },
  getters: {
    user: (state) => state.user,
    is_authenticated: function (state) {
      if (state.user === null) return null;
      else return state.user.authenticated;
    },
    has_role: (state) => (val) => {
      return state.user.roles.indexOf(val) !== -1;
    },
    is_guest: (state) => {
      return state.user.roles.indexOf("guest") !== -1;
    },
    is_investor: (state) => {
      return (
        state.user &&
        state.user.authenticated &&
        state.user.roles.indexOf("investor") !== -1
      );
    },
    is_homeowner: (state) => {
      return state.user.roles.indexOf("homeowner") !== -1;
    },
  },
  actions: {
    fetch_user_data(context) {
      //context.dispatch('cart/fetch_cart', null, {root: true})
    },
    signin: function (context, user) {
      return backend
        .signin(user)
        .then((response) => response.data)
        .then((result) => {
          context.commit("set_user", result);
          context.dispatch("fetch_user_data");
        });
      //.catch(err => {
      //    context.commit('unset_user')
      //    throw(err)
      //})
    },
    signup: function (context, data) {
      return backend.signup(data).then((response) => {
        context.commit("set_user", response.data);
        context.dispatch("fetch_user_data");
      });
    },
    update_user: function (context, data) {
      return backend.update_profile(data).then((response) => {
        context.commit("set_user_data", response.data);
      });
    },
    signout: function (context) {
      return backend.signout().then(function () {
        context.commit("unset_user");
      });
    },
    get_user: function (context) {
      return backend
        .me()
        .then((response) => response.data)
        .then((result) => {
          context.commit("set_user", result);
          if (context.getters["is_authenticated"]) {
            context.dispatch("fetch_user_data");
          }
        });
    },
  },
};
