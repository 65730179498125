import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import User from "./User";
import UserForOM from "./UserForOM";
import ListYourHome from "./ListYourHome";
import Cart from "./Cart";
import Investor from "./Investor";
import Notifications from "./Notifications";

export default new Vuex.Store({
  state: {
    alerts: [],
    init: false,
    showPublicProfileModal: false,
    showSignUpModal: false,
    showSignInModal: false,
    showProfileEditModal: false,
    showOfferFundingModal: false,
    showPhotoUploadModal: false,
    showAccreditationDocumentUploadModal: false,
    showForgotPwModal: false,
    showStrategyModal: false,
    showLeaderModal: false,
    showConfirmModal: false,
    pageType: "main",
    memo_alerts: 0,
    fulllScreenMode: false,
    isRegionalData: false,
    dueDilligenceDocuments: false,
  },
  getters: {
    isFulllScreenMode: (state) => state.fulllScreenMode,
    isRegionalData: (state) => state.isRegionalData,
    dueDilligenceDocuments: (state) => state.dueDilligenceDocuments,
    memo_alerts: (state) => state.memo_alerts,
    alerts: (state) => state.alerts,
    showSignUpModal: (state) => {
      return state.showSignUpModal;
    },
    showSignInModal: (state) => {
      return state.showSignInModal;
    },
    // showProfileEditModal: (state) => {
    //   return state.showProfileEditModal;
    // },
    showLeaderModal: (state) => {
      return state.showLeaderModal;
    },
    showForgotPwModal: (state) => {
      return state.showForgotPwModal;
    },
    showConfirmModal: (state) => {
      return state.showConfirmModal;
    },
    showPublicProfileModal: (state) => {
      return state.showPublicProfileModal;
    },
    showStrategyModal: (state) => {
      return state.showStrategyModal;
    },
    pageType: (state) => {
      return state.pageType;
    },
  },
  mutations: {
    set_page_full_screen_mode: function (state, val) {
      state.fulllScreenMode = val;
    },
    set_regional_data: function (state, val) {
      state.isRegionalData = val;
    },
    set_due_dilligence_document: function (state, val) {
      state.dueDilligenceDocuments = val;
    },

    memo_alerts: function (state, val) {
      state.memo_alerts = val;
    },
    set_init: function (state, val) {
      state.init = val;
    },
    push_error: function (state, msg) {
      this.commit("push_alert", { message: msg, show: 5, type: "error" });
    },
    push_alert: function (state, data) {
      data["show"] = 5;

      let last = state.alerts[state.alerts.length - 1];
      if (last && last.message === data.message && last.type === data.type) {
        Vue.set(last, "count", last.count ? last.count + 1 : 2);
        Vue.set(last, "show", 5);
      } else {
        state.alerts.push(data);
      }
    },
    clear_errors: function (state) {
      state.alerts = state.alerts.filter((x) => x.type !== "error");
    },
    clear_alerts: function (state) {
      if(state){
        state.alerts = [];
      }
    },
    toggleSignUpModal(state) {
      state.showSignUpModal = !state.showSignUpModal;
    },
    toggleConfirmModal(state) {
      state.showConfirmModal = !state.showConfirmModal;
    },
    toggleLoginModal(state) {
      state.showSignInModal = !state.showSignInModal;
    },
    // toggleProfileEditModal(state) {
    //   state.showProfileEditModal = !state.showProfileEditModal;
    // },
    toggleOfferFundingModal(state) {
      state.showOfferFundingModal = !state.showOfferFundingModal;
    },
    togglePhotoUploadModal(state) {
      state.showPhotoUploadModal = !state.showPhotoUploadModal;
    },
    toggleAccreditationDocumentUploadModal(state) {
      state.showAccreditationDocumentUploadModal = !state.showAccreditationDocumentUploadModal;
    },
    toggleForgotPwModal(state) {
      state.showForgotPwModal = !state.showForgotPwModal;
    },
    togglePublicProfileModal(state) {
      state.showPublicProfileModal = !state.showPublicProfileModal;
    },
    toggleStrategyModal(state) {
      state.showStrategyModal = !state.showStrategyModal;
    },
    toggleLeaderModal(state) {
      state.showLeaderModal = !state.showLeaderModal;
    },
    set_page_type(state, val) {
      state.pageType = val;
    },
  },
  actions: {
    init(context) {},
    submit_page_fullscreen_mode(context, val) {
      context.commit("set_page_full_screen_mode", val);
    },
  },
  modules: {
    user: User,
    userForOM: UserForOM,
    list_your_home: ListYourHome,
    cart: Cart,
    investor: Investor,
    notifications: Notifications,
  },
});
