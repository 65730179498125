import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import backend from "@/services/backend";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
  configure,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearchDollar,
  faFileInvoiceDollar,
  faKey,
  faAddressCard,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faSearchDollar, faFileInvoiceDollar, faKey, faAddressCard, faHome);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// install rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

// vee-validate new york state validation
extend("ny", {
  validate(value) {
    if (value) {
      const str = value.toLowerCase();
      return !str.includes("new york") && str !== "ny";
    }
    return false;
  },
  message:
    "Invown is not currently available to New York based issuers and Investors, we will let you know once this has changed",
});

// Vee-validate URL validation

extend("url", {
  validate(value) {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
      );
    }

    return false;
  },
  message: "This value must be a valid URL",
});

// Vee-validate - Create required rule

// Vee-Validate - set classes depending on validation state
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
});

localize("en", en);

// Install components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

export const bus = new Vue();

// Add Unleash (unleash.js + UnleashFeature.vue)
import VueUnleash from "./unleash";
Vue.use(VueUnleash, {
  url: "https://unleash-proxy.invown.com/proxy",
  clientKey: "H5XypN2fPu6dMFbHJNZ",
  appName: "invown-frontend",
});

// Add sentry
//import * as Sentry from "@sentry/browser";
//import { Vue as VueIntegration } from "@sentry/integrations";
//import { Integrations } from "@sentry/tracing";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  //integrations: [
  //  new VueIntegration({
  //    Vue,
  //    tracing: true,
  //    logErrors: true,
  //  }),
  //  //new Integrations.BrowserTracing(),
  //],
  //tracesSampleRate: 1,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "dev.invown.com",
        "demo.invown.com",
        "invown.com",
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// global error handler
Vue.config.errorHandler = function (error, vm, info) {
  // check for invalid api usage error and show alert.
  // do this here instead of in interceptor to give caller chance to handle error
  if (error.isAxiosError) {
    if (error.response.status === 400) {
      // if (error.response.data.message === 'User must have state and zipcode') {
      //   store.commit("toggleProfileEditModal");
      // }else{
      //   store.commit('push_alert', {
      //       message: error.response.data.message,
      //       type: 'error',
      //       show: true,
      //       error: error.response.data
      //   })
      // }

      store.commit("push_alert", {
        message: error.response.data.message,
        type: "error",
        show: true,
        error: error.response.data,
      });
    } else if (error.response.status === 401) {
      store.commit("push_error", "UnAuthorized User");
      store.commit("user/unset_user");
      let fromPath = router.history.current.path;
      if (fromPath !== "/signin") {
        router.push({ name: "signin", params: { from: fromPath } });
      }
    } else if (error.response.status === 405) {
      store.commit("push_error", "Method Not Allowed");
    } else if (error.response.status === 404) {
      store.commit("push_error", "Not Found");
    } else if (error.response.status === 500) {
      store.commit("push_error", "Server Error");
    } else if (error.response.status === 403) {
      store.commit("push_error", "Permission Denied");
    } else {
      throw error;
    }
  } else {
    throw error;
  }
};

// Add GTM
import VueGtm from "@gtm-support/vue2-gtm";
Vue.use(VueGtm, {
  id: "GTM-MVDCH4S",
  //queryParams: {
  //    gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //    gtm_preview: 'env-4',
  //    gtm_cookies_win: 'x'
  //},
  defer: false,
  compatibility: false,
  //nonce: '2726c7f26c',
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  ignoredViews: [],
  trackOnNextTick: false,
});

router.beforeEach((to, from, next) => {
  store.commit("clear_alerts");
  // keep next query parameter when navigating
  if (
    to.path !== "/signout" &&
    to.path !== "/signin" &&
    to.path !== "/signup"
  ) {
    store.commit(
      "set_page_type",
      to.path.includes("/tools") ? "tools" : "main"
    );
  }
  if (from.query.next && !to.query.next) {
    let q = Object.assign({}, to.query, { next: from.query.next });
    next({ path: to.path, query: q });
  } else {
    next();
  }
});

// Add utils as plugin
import UtilsPlugin from "./utils/plugin.js";
Vue.use(UtilsPlugin);

// Add vue-social-sharing
import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

// Add vue-clipboard2
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import NProgress from "nprogress";
import "./styles/nprogress.scss";
NProgress.configure({
  trickle: true,
  showSpinner: false,
});

// Add google auth
import GAuth from "vue-google-oauth2";
const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: "profile email",
  prompt: "select_account",
};
Vue.use(GAuth, gauthOption);

import Breadcrumb from "@/components/common/Breadcrumb";
Vue.component("breadcrumb", Breadcrumb);

// Add axios
Vue.prototype.$http = axios;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;

let isRefreshing = false; // Flag to track if the token refresh is in progress
let failedQueue = []; // Queue to hold requests that need to be retried

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error); // Reject the promise with the error
    } else {
      prom.resolve(token); // Resolve the promise with the new token
    }
  });

  failedQueue = []; // Clear the queue
};

axios.interceptors.response.use(
  async (response) => {
    if (
      response.data &&
      response.data.status &&
      response.data.status.code === "401" &&
      (response.data.status.memo === "Bearer token not found" || response.data.status.memo === "Bearer Token Not OK" || response.data.status.memo === "Expired token"
      )
    ) {
      console.log("Simulating a 401 error for the interceptor");

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const refreshToken = localStorage.getItem("refreshToken");
          const newAccessToken = await backend.refreshsppx({ refresh_token: refreshToken }); 
          store.commit("user/set_sppx_token", newAccessToken.data.token.access_token);
          localStorage.setItem("sppxToken", newAccessToken.data.token.access_token);
          store.commit("user/set_refresh_token", newAccessToken.data.token.refresh_token);
          localStorage.setItem("refreshToken", newAccessToken.data.token.refresh_token);
          isRefreshing = false;  
          return axios({
            ...response.config,
            data: {
              ...JSON.parse(response.config.data),
              sppx_token: newAccessToken.data.token.access_token,
            },
          });
        } catch (refreshError) {
          // console.error("Refresh token failed, attempting sppx_login", refreshError);
          try {
            const sppxResponse = await backend.signinsppx();
            const sppxToken = sppxResponse.data.token.access_token;
            const sppxRefreshToken = sppxResponse.data.token.refresh_token;
            localStorage.setItem("sppxToken", sppxToken);
            localStorage.setItem("refreshToken", sppxRefreshToken);
            store.commit("user/set_sppx_token", sppxToken);
            store.commit("user/set_refresh_token", sppxRefreshToken);
            isRefreshing = false;
            return axios({
              ...response.config,
              data: {
                ...JSON.parse(response.config.data),
                sppx_token: sppxToken,
              },
            });
          } catch (loginError) {
          store.dispatch("user/logout");
          isRefreshing = false;
          return Promise.reject(loginError);
          }
        }
      } 
      return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          if (!isRefreshing) {
            clearInterval(interval);
            resolve(axios(response.config));
          }
        }, 500); // Adjust interval if needed
      });
    }
    return response;
  },
  (error) => {
    console.error("Response error:", error);
    return Promise.reject(error);
  }
);


axios.interceptors.request.use((config) => { 
  const skipBaseURL = 'https://storage.googleapis.com/invown';
  if (!config.url.startsWith(skipBaseURL) && (["/api/me", "/api/notifications"].indexOf(config.url) === -1)) {
    NProgress.start();
  }
  return config;
});
axios.interceptors.response.use(
  (response) => { 
    if (!response.config.url.startsWith('https://storage.googleapis.com/invown')) {
      NProgress.done();
    }
    return response;
  },
  (error) => { 
    if (!error.config.url.startsWith('https://storage.googleapis.com/invown')) {
      NProgress.done();
    }
    return Promise.reject(error);
  }
);
// add cloudinary components:
import Cloudinary, {
  CldImage,
  CldPlaceholder,
  CldTransformation,
} from "cloudinary-vue";
Vue.use(Cloudinary, {
  configuration: {
    cloudName: "invown-corp",
  },
  components: [CldImage, CldPlaceholder, CldTransformation],
});

// Add bootstrap
import {
  AlertPlugin,
  ModalPlugin,
  NavPlugin,
  NavbarPlugin,
  CardPlugin,
  FormFilePlugin,
  FormDatepickerPlugin,
  FormCheckboxPlugin,
  FormInputPlugin,
  InputGroupPlugin,
  OverlayPlugin,
  SidebarPlugin,
  LayoutPlugin,
  TooltipPlugin,
  ButtonPlugin,
  DropdownPlugin,
  FormGroupPlugin,
  FormPlugin,
  ImagePlugin,
  SkeletonPlugin,
  LinkPlugin,
  PopoverPlugin,
  CarouselPlugin,
  ProgressPlugin,
  FormTextareaPlugin,
  SpinnerPlugin,
  TablePlugin,
  FormSelectPlugin,
  FormRadioPlugin,
  VBHoverPlugin,
} from "bootstrap-vue";
//import './styles/custom.scss'
[
  AlertPlugin,
  ModalPlugin,
  NavPlugin,
  NavbarPlugin,
  CardPlugin,
  FormFilePlugin,
  FormDatepickerPlugin,
  FormCheckboxPlugin,
  FormInputPlugin,
  InputGroupPlugin,
  OverlayPlugin,
  SidebarPlugin,
  LayoutPlugin,
  TooltipPlugin,
  ButtonPlugin,
  DropdownPlugin,
  FormGroupPlugin,
  FormPlugin,
  ImagePlugin,
  SkeletonPlugin,
  LinkPlugin,
  PopoverPlugin,
  CarouselPlugin,
  ProgressPlugin,
  FormTextareaPlugin,
  SpinnerPlugin,
  TablePlugin,
  FormSelectPlugin,
  FormRadioPlugin,
  VBHoverPlugin,
].forEach((plugin) => {
  Vue.use(plugin);
});

import {
  BIcon,
  BIconHeart,
  BIconCheckCircleFill,
  BIconXCircleFill,
  BIconChevronUp,
  BIconChevronDown,
  BIconPencil,
  BIconGridFill,
  BIconGrid3x3GapFill,
  BIconInfoCircle,
  BIconHeartFill,
  BIconShare,
  BIconCaretUp,
  BIconCaretDown,
  BIconCaretUpSquareFill,
  BIconCaretDownSquareFill,
  BIconInfoCircleFill,
  BIconLock,
  BIconPhone,
  BIconPlus,
  BIconPencilSquare,
  BIconTrashFill,
} from "bootstrap-vue";

Vue.component("BIcon", BIcon);
Vue.component("BIconHeart", BIconHeart);
Vue.component("BIconHeartFill", BIconHeartFill);
Vue.component("BIconCheckCircleFill", BIconCheckCircleFill);
Vue.component("BIconXCircleFill", BIconXCircleFill);
Vue.component("BIconChevronUp", BIconChevronUp);
Vue.component("BIconChevronDown", BIconChevronDown);
Vue.component("BIconPencil", BIconPencil);
Vue.component("BIconGridFill", BIconGridFill);
Vue.component("BIconGrid3x3GapFill", BIconGrid3x3GapFill);
Vue.component("BIconInfoCircle", BIconInfoCircle);
Vue.component("BIconInfoCircleFill", BIconInfoCircleFill);
Vue.component("BIconShare", BIconShare);
Vue.component("BIconCaretUp", BIconCaretUp);
Vue.component("BIconCaretDown", BIconCaretDown);
Vue.component("BIconCaretUpSquareFill", BIconCaretUpSquareFill);
Vue.component("BIconCaretDownSquareFill", BIconCaretDownSquareFill);
Vue.component("BIconLock", BIconLock);
Vue.component("BIconPhone", BIconPhone);
Vue.component("BIconPlus", BIconPlus);
Vue.component("BIconPencilSquare", BIconPencilSquare);
Vue.component("BIconTrashFill", BIconTrashFill);

Vue.config.productionTip = false;

// Add lodash
import VueLodash from "vue-lodash";
import lodash from "lodash";
Vue.use(VueLodash, { name: "custom", lodash: lodash });

// Add vue-cropperjs
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
Vue.component("vue-cropper", VueCropper);

// Add momentjs
import moment from "moment";
Vue.use(require("vue-moment"), { moment });

import VueMeta from "vue-meta";
Vue.use(VueMeta);

// Add vue-file-agent

import VueHorizontal from "vue-horizontal";
Vue.use(VueHorizontal);

import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY });

// Sync window title with route name
//router.afterEach((toRoute, fromRoute) => {
//    window.document.title = toRoute.name || 'Invown';
//});

// Add analytics
import VueGtag from 'vue-gtag'
Vue.use(VueGtag, {
  config: {
    id: 'G-TML9BV7QPD',
    params: {
     send_page_view: true
    }
  },
  appName: 'Invown App',
  pageTrackerScreenviewEnabled: true,
}, router);
import i18n from "./i18n";

new Vue({
  router,
  store,
  axios,
  i18n,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
