import Vue from "vue";
import backend from "@/services/backend";

const getDefaultState = () => {
  return {
    state: {
      cart: {
        items: [],
        active: false,
      },
      terms: {
        first: false,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
      },
      payment_update_item: null,
      reservation: null,
      reservations: [],
      selected_account: null,
      mainStep: 1,
      mainStepReservation: 1,
      checkout_data: null,
      // checkout
      investor_entity: null,
      other_entity: null,
      investor: null,
      subscription_agreement_map: {},
      offering_id_map: {},
      payment_method: "ach",
      investment_cancel_error: null,
      activity: [],
    },
  };
};

export default {
  namespaced: true,
  state: {
    cart: {
      items: [],
      active: false,
    },
    terms: {
      first: false,
      second: false,
      third: false,
      fourth: false,
      fifth: false,
    },
    reservation: null,
    selected_account: null,
    mainStep: 1,
    mainStepReservation: 1,
    checkout_data: null,
    // checkout
    investor_entity: null,
    other_entity: null,
    investor: null,
    subscription_agreement_map: {},
    offering_id_map: {},
    payment_method: "ach",
    investment_cancel_error: null,
    activity: [],
  },

  actions: {
    resetCartState({ commit }) {
      commit("resetState");
    },
    ensure_active_cart(context) {
      if (!context.state.cart.active) {
        return context.dispatch("fetch_cart");
      }
      return Promise.resolve();
    },
    fetch_cart(context) {
      return backend.get_cart().then((response) => {
        context.state.cart = response.data;
      });
    },
    async add_to_cart(context, item) {
      await context.dispatch("ensure_active_cart");
      return backend
        .to_cart({
          home_id: item.home.id,
          invest_amount: item.invest_amount,
          type: item.type,
        })
        .then((response) => {
          context.state.cart.items.push(response.data);
        });
    },
    async remove_from_cart(context, item) {
      await context.dispatch("ensure_active_cart");
      return backend.remove_from_cart({ item: item.id }).then((response) => {
        context.state.cart.items = context.state.cart.items.filter(
          (x) => x.id !== item.id
        );
      });
    },
    submit_cart(context) {
      return backend
        .submit_cart({
          cart: context.state.cart.id,
          terms: context.state.terms,
          account: context.state.selected_account,
        })
        .then((response) => {
          context.state.cart.items = [];
          context.state.cart.active = false;
        });
    },
    set_reservation(context, item) {
      context.state.reservation = item;
    },
    set_reservations(context, item) {
      context.state.reservations = item;
    },
    set_transaction_update_item(context, item) {
      context.state.payment_update_item = item;
    },
    like_home(context, home) {
      return backend.like_home(home.id).then((response) => {
        context.commit("user/push_home_like", home.id, { root: true });
        home.likes += 1;
      });
    },
    unlike_home(context, home) {
      return backend.unlike_home(home.id).then((response) => {
        context.commit("user/remove_home_like", home.id, { root: true });
        home.likes -= 1;
      });
    },
    submit_set_payment_method(context, val) {
      context.commit("set_payment_method", val);
    },
    set_investment_error(context, val) {
      context.state.investment_cancel_error = val;
    },
    add_activity(context, val) {
      const entry = context.state.activity.find((item) => item.value === val);
      if (!entry) {
        context.state.activity.push({
          value: val,
          timestamp: new Date(),
        });
      } else {
        entry.timestamp = new Date();
      }
      console.log(context.state.activity);
    },
  },
  getters: {
    investment_cancel_error: (state) => {
      return state.investment_cancel_error;
    },
    get_payment_method: (state) => {
      return state.payment_method;
    },
  },
  mutations: {
    set_payment_method: function (state, val) {
      state.payment_method = val;
    },
    resetState(state) {
      Object.assign(state, getDefaultState().state);
    },
  },
};
