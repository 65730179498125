import UnleashFeature from "@/components/UnleashFeature.vue";
import { UnleashClient } from "unleash-proxy-client";

const install = (Vue, { url, clientKey, appName }) => {
  const unleash = new UnleashClient({
    url: url,
    clientKey: clientKey,
    appName: appName,
  });

  // Start the background polling
  unleash.start();

  Vue.component("unleash-feature", UnleashFeature);
  Vue.prototype.$unleash = unleash;

  if (
    window.location.host !== "www.invown.com" &&
    window.location.host !== "invown.com" &&
    window.location.host !== "dev.invown.com" &&
    window.location.host !== "www.dev.invown.com"
  ) {
    unleash.isEnabled = (x) => true;
  }

  //unleash.on('update', () => {

  //});
};

const plugin = {
  UnleashFeature,
  install,
};

export default plugin;

if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use(plugin);
}
