<template>
  <div id="app" class="h-100">
    <router-view />
  </div>
</template>

<script>
export default {
  metaInfo() {
    const location = window.location;
    return {
      title: "Invown - Marketplace",
      meta: [
        {
          name: "description",
          content:
            "Invown is a two-sided marketplace where companies offer investment opportunities to everyday investors.",
        },
        {
          property: "og:description",
          content: "A New Alternative To Real Estate Investing",
        },
        { property: "og:title", content: "Invown - Marketplace" },
        { property: "og:site_name", content: "Invown" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
      link: [
        {
          rel: "canonical",
          href: location.protocol + "//" + location.host + this.$route.fullPath,
        },
      ],
    };
  },
};
</script>

<style>
html,
body {
  height: 100%;
  @font-face {
    font-family: Inter;
    font-display: swap;
  }
}

.modal-fullscreen .modal-dialog {
  max-width: min(1000px, calc(100% - 3.5rem));
  height: 100%;
}
.my-carousel .carousel-indicators li {
  background-color: red;
}
.my-carousel,
.my-carousel .carousel-inner,
.my-carousel img {
  height: 320px;
  width: 450px;
}
.my-carousel img {
  object-fit: cover;
  border-radius: 1.6rem;
  border-bottom-right-radius: 0;
}
.my-carousel .carousel-caption {
  color: red;
  text-align: left;
  bottom: 0;
  left: 8%;
  padding-bottom: 5px;
}
</style>

<!-- 
max-width: 100%;
margin: 0;
top: 0;
bottom: 0;
left: 0;
right: 0;
display: flex;
position: fixed;
z-index: 100000;
-->