import Vue from "vue";
import backend from "@/services/backend";

export default {
  namespaced: true,
  state: {
    user: null,
    listings: [],
    portfolio: [],
    sppxToken: null,     
    refreshToken: null,   
  },
  mutations: {
    set_user: function (state, val) {
      state.user = val;
    },
    set_user_data: function (state, data) {
      for (const key of Object.keys(data)) {
        Vue.set(state.user, key, data[key]);
      }
    },
    set_investor_data: function (state, data) {
      for (const key of Object.keys(data)) {
        Vue.set(state.user.investor_profile, key, data[key]);
      }
    },
    unset_user: function (state) {
      state.user = { authenticated: false, roles: [], homes: [] };
    },
    unset_portfolio: function (state) {
      state.portfolio = [];
    },
    push_home(state, val) {
      state.user.homes.push(val);
    },
    push_bank_account(state, val) {
      state.user.bank_accounts.push(val);
    },
    push_home_like(state, home_id) {
      state.user.home_likes.push(home_id);
    },
    remove_home_like(state, home_id) {
      state.user.home_likes = state.user.home_likes.filter(
        (x) => x !== home_id
      );
    },
    set_confirmed(state, confirmed_at) {
      state.user.confirmed_at = confirmed_at;
    },
    set_listings(state, listings) {
      state.listings = listings;
    },
   
    set_portfolio(state, portfolio) {
      state.portfolio = portfolio;
    },
    set_sppx_token: (state, sppxToken) => {
      state.sppxToken = sppxToken;
    },
    set_refresh_token: (state, refreshToken) => {
      state.refreshToken = refreshToken;
    },
  },
  getters: {
    user: (state) => state.user,
    listings: (state) => state.listings,
    portfolio: (state) => state.portfolio,
    is_authenticated: function (state) {
      if (state.user === null) return null;
      else return state.user.authenticated;
    },
    has_role: (state) => (val) => {
      return state.user.roles.indexOf(val) !== -1;
    },
    is_guest: (state) => {
      return state.user.roles.indexOf("guest") !== -1;
    },
    is_investor: (state) => {
      return (
        state.user &&
        state.user.authenticated &&
        state.user.roles.indexOf("investor") !== -1
      );
    },
    is_homeowner: (state) => {
      return state.user.roles.indexOf("homeowner") !== -1;
    },
    sppxToken: (state) => state.sppxToken, 
    refreshToken: (state) => state.refreshToken
  },
  actions: {
    signin: function (context, user) {
      return backend
        .signin(user)
        .then((response) => response.data)
        .then((result) => {
          context.commit("set_user", result);
    
          // Directly call the SPPX signin API without sending sppxUserData
          backend.signinsppx()
            .then((response) => {
              const sppxToken = response.data.token.access_token;
              const refreshToken = response.data.token.refresh_token;
    
              // Store the tokens
              localStorage.setItem('sppxToken', sppxToken);
              localStorage.setItem('refreshToken', refreshToken);
              context.commit("set_sppx_token", sppxToken);
              context.commit("set_refresh_token", refreshToken);
    
              // Trigger analytics event for SPPX login
              window.dataLayer.push({
                event: "login",
                user_id: result.id,
                auth_provider: "sppx",
              });
            })
            .catch((err) => {
              // SPPX login failed, but we continue with normal login
              console.error("SPPX Login failed", err);
            });
    
          // Push analytics event for normal login
          window.dataLayer.push({
            event: "signin",
            user_id: result.id,
            auth_provider: user.provider,
          });
        })
        .catch((err) => {
          context.commit('unset_user');
          throw err;
        });
    },       
    signup: function (context, data) {
      return backend.signup(data).then((response) => {
        context.commit("set_user", response.data);
        return backend.signinsppx().then((loginResponse) => {
          const newUserToken = loginResponse.data.token.access_token;
          const newUserrefreshToken = loginResponse.data.token.refresh_token;
    
          // Commit and store the SPPX token and refresh token
          context.commit("set_sppx_token", newUserToken);
          context.commit("set_refresh_token", newUserrefreshToken);
          localStorage.setItem('sppxToken', newUserToken);
          localStorage.setItem('refreshToken', newUserrefreshToken);
    
          // Push analytics event for SPPX login
          window.dataLayer.push({
            event: "login",
            user_id: response.data.id,
            auth_provider: "sppx",
          });
        });
      });
    },
    update_user: function (context, data) {
      return backend.update_profile(data).then((response) => {
        context.commit("set_user_data", response.data);
      });
    },
    signout: function (context) {
      return backend.signout().then(function () {
        localStorage.removeItem('sppxToken');
        localStorage.removeItem('refreshToken'); 
        context.commit("set_sppx_token", null); 
        context.commit("set_refresh_token", null);
        window.dataLayer.push({
          event: "signout",
          user_id: null, //context.state.user.id
        });
        context.commit("unset_user");
        context.commit("unset_portfolio");
      });
    },
    get_user: function (context) {
      return backend
        .me()
        .then((response) => response.data)
        .then((result) => {
          context.commit("set_user", result);
          if (result.authenticated) {
            window.dataLayer.push({
              event: "authenticated",
              user_id: result.id,
            });
          } else {
            window.dataLayer.push({
              event: "unauthenticated",
              user_id: null,
            });
          }
        });
    },
    get_listings: function (context) {
      return backend
        .get_listings()
        .then((response) => response.data)
        .then((result) => {
          context.commit("set_listings", result);
        });
    },
    get_portfolio: function (context) {
      return backend
        .get_portfolio()
        .then((response) => response.data)
        .then((result) => {
          context.commit("set_portfolio", result);
        });
    },
  },
};