export default {
  async init_facebook() {
    return new Promise((resolve) => {
      if (window.FB && window.FB.init) {
        resolve(window.FB);
        return;
      }
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: "v13.0",
        });
        resolve(window.FB);
      };

      (function (d, s, id) {
        let js, fjs;
        fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    });
  },
  async ensure_login() {
    const extract_auth = (response) => {
      return {
        provider_user_id: response.authResponse.userID,
        access_token: response.authResponse.accessToken,
      };
    };
    return new Promise((resolve) => {
      window.FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          console.log(response);
          // already logged in with facebook
          resolve(extract_auth(response));
        } else {
          window.FB.login(
            (response) => {
              console.log(response);
              if (response.status === "connected") {
                resolve(extract_auth(response));
              } else {
                console.log("login cancelled", response);
              }
            },
            { scope: "email" }
          );
        }
      });
    });
  },
  async ensure_logout() {
    return new Promise((resolve) => {
      window.FB.getLoginStatus(async (response) => {
        if (response.status !== "unknown") {
          console.log("fb logout status", response);
          window.FB.logout(function (response) {
            resolve();
          });
        } else {
          resolve();
        }
      });
    });
  },
};
