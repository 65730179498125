import Vue from "vue";
import backend from "@/services/backend";

const get_default_state = () => {
  return {
    hasAddress: false,
    steps: [],
    currentLYPstep: 0,
    current: null,
    home: {
      id: null,
      address: { street: "", city: "", state: "", zipcode: "", appartment: "" },
      debt: 0,
      additional_debt: 0,
      credit_score: "580-669",
      cash_goal: 1,
      equity_offer: 0.01,
      term_of_investment: 10,
      use: "",
      model: "ee",
      valuation: null,
      interest: 1.0,
      eeprop: 1,
      avghomeapp: 3.9,
      funding_round_end: "",
      about_owner: "",
      about_home: "",
      money_allocation: [],
      rental_details: {},
    },
    agreed: false,
    default_money_allocation: [
      { name: "Home renovations", enabled: false, amount: null },
      { name: "Pay of debt", enabled: false, amount: null },
      { name: "Living expenses", enabled: false, amount: null },
      { name: "", other: true, enabled: false, amount: null },
    ],
  };
};

const state = get_default_state();

const getters = {
  current_lyp_step(state) {
    return state.currentLYPstep;
  },
  next_step(state) {
    let current_index = state.steps.indexOf(state.current);
    let i = current_index + 1;
    if (i < state.steps.length) {
      if (state.steps[i].skip && state.steps[i].skip()) {
        i = current_index + 2;
        if (i < state.steps.length) {
          return state.steps[i];
        } else {
          return null;
        }
      } else {
        return state.steps[i];
      }
    }
    return null;
  },
  previous_step(state) {
    let current_index = state.steps.indexOf(state.current);
    let i = current_index - 1;
    if (i >= 0) {
      if (state.steps[i].skip && state.steps[i].skip()) {
        i = current_index - 2;
        if (i >= 0) {
          return state.steps[i];
        } else {
          return null;
        }
      } else {
        return state.steps[i];
      }
    }
    return null;
  },
  current_step(state) {
    return state.current;
  },
  completed(state) {
    return state.steps.indexOf(state.current) + 1;
  },
  steps(state) {
    return state.steps;
  },
  hasAddress(state) {
    return state.hasAddress;
  },
};

const mutations = {
  set_has_address(state, val) {
    state.hasAddress = val;
  },
  reset_state(state) {
    Object.assign(state, get_default_state());
  },
  set_lyp_step(state, CurrentStep) {
    state.currentLYPstep = CurrentStep;
  },
  set_home: function (state, h) {
    state.home = h;
    if (h.info) {
      Vue.set(state.home, "address", {
        street: h.info.address.formatted_street_address,
        city: h.info.address.city,
        state: h.info.address.state,
        zipcode: h.info.address.zip_code,
        appartment: h.info.address.unit_number,
      });
    } else {
      Vue.set(state.home, "address", {
        street: "",
        city: "",
        state: "",
        zipcode: "",
        appartment: "",
      });
    }
    state.home.interest = h.interest * 100;
    state.home.eeprop = h.eeprop * 100;
    state.home.avghomeapp = h.avghomeapp * 100;
  },
  set_home_field(state, { field, value }) {
    Vue.set(state.home, field, value);
  },
};

const actions = {
  async calc_steps(context, home_id) {
    context.commit("reset_state");

    let s = [];

    if (
      context.rootGetters["user/user"].homeowner_profile === null ||
      !context.rootGetters["user/is_homeowner"]
    ) {
      let response = await backend.create_homeowner_profile();
      context.commit(
        "user/set_user_data",
        {
          homeowner_profile: response.data,
          roles: ["homeowner", ...context.rootGetters["user/user"].roles],
        },
        { root: true }
      );
    }

    if (home_id) {
      let h = (await backend.get_home(home_id)).data;
      context.commit("set_home", h);
    }

    s.push({
      title: "Property address",
      component: "ListYourHomeAddress",
      submit(step) {
        if (home_id || context.state.home.id) {
          return backend
            .update_home({
              id: context.state.home.id,
              address: context.state.home.address,
              step: step,
            })
            .then((response) => {
              context.commit("set_home", response.data);
            });
        } else {
          return backend
            .add_home({
              address: context.state.home.address,
              step: step,
            })
            .then((response) => {
              context.commit("set_home", response.data);
              context.commit("user/push_home", response.data, { root: true });
            });
        }
      },
    });

    s.push({
      title: "Valuation",
      component: "ListYourHomeValuation",
      submit: (step) => {
        context.state.home.cash_goal = Math.max(
          25000,
          0.1 * context.state.home.valuation
        );
        return backend.update_home({
          id: context.state.home.id,
          valuation: context.state.home.valuation,
          step: step,
        });
      },
    });
    s.push({
      title: "Property use",
      component: "ListYourHomeUse",
      hide_next_button: true,
      submit(step) {
        return backend.update_home({
          id: context.state.home.id,
          use: context.state.home.use,
          step: step,
        });
      },
    });
    s.push({
      title: "Debt",
      component: "ListYourHomeDebt",
      submit(step) {
        return backend.update_home({
          id: context.state.home.id,
          debt: parseFloat(context.state.home.debt),
          additional_debt: parseFloat(context.state.home.additional_debt),
          step: step,
        });
      },
    });
    //s.push({
    //    title: 'Cash goal',
    //    component: 'ListYourHomeCashGoal',
    //    submit(step) {
    //        return axios.post('/api/homes/update', {
    //            id: context.state.home.id,
    //            cash_goal: parseInt(context.state.home.cash_goal),
    //            equity_offer: parseFloat(context.state.home.equity_offer),
    //            step: step
    //        })
    //    }
    //})
    //s.push({
    //    title: 'Equity offer',
    //    component: 'ListYourHomeEquityOffer',
    //    submit() {return Promise.resolve()}
    //})
    //s.push({
    //    title: 'Term of investment',
    //    component: 'ListYourHomeTermOfInvestment',
    //    submit (step) {
    //        return axios.post('/api/homes/update', {
    //            id: context.state.home.id,
    //            term_of_investment: parseInt(context.state.home.term_of_investment),
    //            step: step
    //        })
    //    }
    //})
    s.push({
      title: "Preliminary terms of your offering",
      component: "ListYourHomeModel",
      submit(step) {
        return backend
          .update_home({
            id: context.state.home.id,
            interest: context.state.home.interest / 100,
            eeprop: context.state.home.eeprop / 100,
            avghomeapp: context.state.home.avghomeapp / 100,
            model: context.state.home.model,
            cash_goal: context.state.home.cash_goal,
            equity_offer: parseFloat(context.state.home.equity_offer),
            term_of_investment: context.state.home.term_of_investment,
            step: step,
          })
          .then((response) => {
            context.commit("set_home", response.data);
          });
      },
    });
    //s.push({
    //    title: 'Money Allocation',
    //    component: 'ListYourHomeMoneyAllocation',
    //    submit (step) {
    //        return axios.post('/api/homes/update', {
    //            id: context.state.home.id,
    //            money_allocation: context.state.home.money_allocation,
    //            step: step
    //        })
    //            .then(response => {
    //                context.commit('set_home', response.data)
    //            })
    //    }
    //})
    //s.push({
    //    title: 'Offering deadline',
    //    component: 'ListYourHomeFundingRoundTime',
    //    submit  (step) {
    //        if (!context.state.home.funding_round_end) {
    //            context.commit('push_error', 'Must specify funding round end',
    //                           {root: true})
    //            return Promise.reject()
    //        }
    //        return axios.post('/api/homes/update', {
    //            id: context.state.home.id,
    //            funding_round_end: context.state.home.funding_round_end,
    //            step: step
    //        })
    //    }
    //})
    //s.push({
    //    title: 'Rental Details',
    //    component: 'ListYourHomeRentalDetails',
    //    skip() {
    //        return context.state.home.use !== 'rental_property'
    //    },
    //    submit (step) {
    //        return axios.post('/api/homes/update', {
    //            id: context.state.home.id,
    //            step: step,
    //            rental_details: context.state.home.rental_details
    //        })
    //    }
    //})
    //s.push({
    //    title: 'Images',
    //    component: 'ListYourHomeImages',
    //    submit (step) {
    //        return axios.post('/api/homes/update', {
    //            id: context.state.home.id,
    //            step: step
    //        })
    //    }
    //})
    //s.push({
    //    title: 'About',
    //    component: 'ListYourHomeAbout',
    //    submit (step) {
    //        return axios.post('/api/homes/update', {
    //            id: context.state.home.id,
    //            about_owner: context.state.home.about_owner,
    //            about_home: context.state.home.about_home,
    //            step: step
    //        })
    //    }
    //})
    s.push({
      title: "Review",
      component: "ListYourHomeReview",
      submit_on_back: false,
      submit(step) {
        if (!context.state.agreed) {
          context.commit("push_error", "Must agree to issuer agreement", {
            root: true,
          });
          return Promise.reject();
        }
        return backend.update_home({
          id: context.state.home.id,
          submitted: true,
          step: step,
        });
      },
    });
    s.push({
      title: "Done",
      component: "ListYourHomeDone",
      hide_next_button: true,
      submit(step) {
        return Promise.resolve();
      },
    });
    context.state.steps = s;

    let step = context.state.steps.find(
      (x) => x.title === context.state.home.step
    );
    context.state.current = step || context.state.steps[0];
  },
  submit_credit_score(context, val) {
    context.state.home.credit_score = val;
    context.dispatch("go_next");
  },
  submit_home_use(context, val) {
    context.state.home.use = val;
    context.dispatch("go_next");
  },
  submit_lyp_step(context, step) {
    context.commit("set_lyp_step", step);
  },
  async go_next(context) {
    try {
      await context.state.current.submit(context.getters.next_step.title);
    } catch {
      return;
    }
    context.commit("clear_alerts", null, { root: true });
    context.state.current = context.getters.next_step;
  },
  async go_back(context) {
    if (context.getters.previous_step) {
      if (context.state.current.submit_on_back !== false) {
        try {
          await context.state.current.submit(
            context.getters.previous_step.title
          );
        } catch {
          return;
        }
      }
      context.commit("clear_alerts", null, { root: true });
      context.state.current = context.getters.previous_step;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
